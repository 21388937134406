import React, { Component } from 'react';
import Layout from '../components/layout';
import Home from '../data/home.json'
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet';
import gfm from 'remark-gfm';


const IndexPage = () => {
  return (
    <Layout>
        <Helmet>
          <title>{Home.title}</title>
      </Helmet>
      <section id="page-title" className="page-title-parallax page-title-dark include-header skrollable skrollable-between" style={{backgroundImage: `url('${Home['title-image']}')`, padding: '250px 0px', backgroundSize: 'cover', backgroundPosition: '0px -128.641px', marginTop: '-102px'}}  data-bottom-top="background-position:0px 400px;" data-top-bottom="background-position:0px -500px;">
            <div className="container clearfix home-title">
                <h1>{Home.title}</h1>
                <span>{Home.quote}</span>
            </div>
        </section>
        <section id="content">
            <div className="content-wrap home-content">
                <div className="container clearfix">
                    <div className="row col-mb-50">
                        <div className="col-12">
                            <ReactMarkdown plugins={[gfm]} children={Home.body} />
                        </div>
                        <div className="col-12"><h2 style={{textAlign: "center"}}>Witnessing Bible</h2></div>
                        <div className="col-md-4">
                          <img src={Home['witnessing-image']} style={{width: "80%", height: "auto", marginLeft: "auto", float: "right"}} />
                        </div>
                        <div className="col-md-8">
                        <ReactMarkdown plugins={[gfm]} children={Home.witnessing} />
                        </div>
                        <div className="col-12"><h2 style={{textAlign: "center"}}>Christian Witnessing Cards</h2></div>
                        <div className="col-md-4">
                        <img src={Home['witnessing-card-image']} style={{width: "80%", height: "auto", marginLeft: "auto", float: "right"}} />
                        </div>
                        <div className="col-md-8">
                        <ReactMarkdown plugins={[gfm]} children={Home['witnessing-card']} />
                        </div>
                        <div className="col-12">
                        <ReactMarkdown plugins={[gfm]} children={Home['body-closing']} />
                        </div>
                    </div>
                </div>
            </div>    
        </section>          
    </Layout>
  )
}

export default IndexPage
